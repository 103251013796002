import React from "react";

import css from "./skills.module.scss";

const Skills = () => (
  <section className={css.skills}>
    <div className={css.skillsHeading}>
      <h2 className={css.skillsHeadingText}>Skills</h2>
    </div>

    <div className={css.skillsContent}>
      <ul>
        <li>
          <dl>
            <dt>frontend</dt>
            <dd>
              I've delivered complex webapps using JavaScript's modern
              incarnations for the past decade; including TypeScript for five
              years. From a solid foundation of semantic HTML and CSS to
              advanced React, ESM, code-splitting, service workers, and
              more&mdash;the web is my platform of choice and the place I shine
              brightest.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>backend</dt>
            <dd>
              Currently building in Elixir's Phoenix Framework. Comfortable in
              Node and SQL (Postgres/Oracle/MS SQL Server/MySQL/mongoDB). It's
              been a minute, but I've worked in Clojure, Java, and PHP, even a
              dash of Ruby and Perl (talk to me about regular expressions :).
              Functional or object-oriented, I will come up to speed quickly in
              your backend. <br />
              I've used all the most common AWS services, including
              Lambda/Serverless, S3, CloudFront, and more. I have strong
              opinions, loosely held, around fluent REST API design. I find
              GraphQL powerful (though overhead-heavy). I'm fascinated by
              scaled, distributed systems; both the capabilities and challenges.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>devOps</dt>
            <dd>
              Comfortable with AWS, Docker, and Kubernetes. Both professionally
              and for open source, I have maintained CI/CD pipelines using
              Github Actions, Jenkins, and CircleCI. I've instrumented Elixir
              and JavaScript apps with observability solutions like Heap
              Analytics, Sentry, and Datadog using OpenTelemetry as well as
              proprietary in-house distributed tracing systems similar to
              Jaeger.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>remote work</dt>
            <dd>
              After 5 years of remote work, I have a hard-won knowledge of the
              unique ingredients necessary for communication and collaboration
              in a distributed team. I have a reputation for transparent status
              updates, productive asynchronous work, and for fostering community
              within the team even from a distance.
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </section>
);

export default Skills;
